import service from "@/utils/request";


//获取 指定app  指定支付接口 的参数信息
export const getPayInterfaceParams = (recognizeId, ifCode) => service({
    url: '/payInterfaceConfig/getPayInterfaceConfig',
    method: "get",
    params: { recognizeId, ifCode }
});

//获取 指定app  所有支付接口 的开通状态
export const getIfCodeStatus = (appId, ifCodeList) => service({
    url: '/payInterfaceConfig/getIfCodeStatus/' + appId,
    method: "post",
    data: ifCodeList
});

//获取 指定app  指定支付接口 的支付通道的开通状态
export const getPayWayStatus = (appId, ifCode) => service({
    url: '/payInterfaceConfig/getPayWayStatus',
    method: "get",
    params: { "recognizeId": appId, "ifCode": ifCode }
});

//添加
export const addPayInterfaceParams = (data) => service({
    url: '/payInterfaceConfig/add',
    method: "post",
    data: data,
});

// 修改指定APP 指定支付接口 指定支付通道 的开通状态
export const changePayWayStatus = (data) => service({
    url: '/payInterfaceConfig/changePayWayStatus',
    method: "get",
    params: data
});
