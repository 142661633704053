import service from "@/utils/request";


//分页查询所有 （管理员）
export const getMerchantAppInfoData = (params) => service({
    url: '/mchApp/queryPage',
    method: "get",
    params: params
});

//分页查询所有 （商户权限）
// export const getMerchantAllData = (params) => service({
//     url: '/mchApp/my/queryPage',
//     method: "get",
//     params: params
// });

//添加（商户权限）
export const addMerchantAppInfo = (data) => service({
    url: '/mchApp/add',
    method: "post",
    data: data,
});

//更新
export const updateMerchantAppInfo = (data) => service({
    url: '/mchApp/edit',
    method: "post",
    data: data,
});

//删除（一个/多个）
export const deletesMerchantAppInfo = (data) => service({
    url: '/mchApp/delete/ids',
    method: "post",
    data: data,
});

//修改应用状态
export const updateMerchantAppStatus = (appId, status) => service({
    url: `/mchApp/change/${appId}/${status}`,
    method: "post",
});

//生成应用密钥
export const randomKey = () => service({
    url: '/mchApp/keys',
    method: "get",
});