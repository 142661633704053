<template>
  <div>
    <!-- 页头搜索 -->
    <div class="input">
      <div class="input-font">
        <div class="fontdiv">应用ID</div>
        <el-input placeholder="请输入应用ID" v-model="searchForm.appId" clearable size="medium"></el-input>
      </div>
      <div class="input-font" v-if="userRole">
        <div class="fontdiv">商户号</div>
        <el-input placeholder="请输入商户号" v-model="searchForm.mchNo" clearable size="medium"></el-input>
      </div>
      <div class="input-font">
        <div class="fontdiv">应用名称</div>
        <el-input placeholder="请输入应用名称" v-model="searchForm.appName" clearable size="medium"></el-input>
      </div>
      <div class="input-font">
        <div class="fontdiv">应用状态</div>
        <el-select v-model="searchForm.status" placeholder="请选择应用状态" clearable class="select-width"
                   size="medium">
          <el-option label="停用" :value="0"/>
          <el-option label="启用" :value="1"/>
        </el-select>
      </div>

      <el-button class="button-height" type="primary" icon="el-icon-search" :loading="false" @click="getTableData()">搜索
      </el-button>
      <el-button class="button-height" plain icon="el-icon-refresh" @click="resetSearch()">重置</el-button>
    </div>

    <!-- 页头按钮 -->
    <el-row>
      <el-button size="medium" type="success" plain icon="el-icon-download" :disabled="false" @click="add()">添加
      </el-button>
      <el-button size="medium" type="danger" plain icon="el-icon-download" :disabled="false" @click="deleteList()">批量删除
      </el-button>
    </el-row>

    <!-- 列表渲染 -->
    <div class="table">
      <el-table :data="tableData" ref="multipleTable" tooltip-effect="dark" @selection-change="handleSelectionChange">
        <el-table-column type="selection" min-width="60"></el-table-column>
        <el-table-column prop="appId" label="应用ID" min-width="170"></el-table-column>
        <el-table-column prop="appName" label="应用名称" min-width="240"></el-table-column>
        <el-table-column prop="mchNo" label="商户号" min-width="170"></el-table-column>
        <el-table-column prop="status" label="应用状态" min-width="90">
          <template #default="scope">
            <div @click="changeStatus(scope.row)">
              <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="0"></el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="商户备注" min-width="200"></el-table-column>
        <!-- 浮动侧边栏 -->
        <el-table-column label="操作" min-width="190" fixed="right" align="center">
          <template #default="scope">
            <el-link class="marginRight" @click="editRow(scope.row)" type="primary" :underline="false">编辑
            </el-link>
            <el-link class="marginRight" @click="payDisposition(scope.row)" type="primary" :underline="false">支付配置
            </el-link>
            <el-link @click="deleteRow(scope.row.appId)" type="danger" :underline="false">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页器 -->
    <Pagination :total="total" :page="pageNumber" :size="pageSize" @getPage="getPage($event)"
                @getSize="getSize($event)">
    </Pagination>

    <!-- 添加 -->
    <el-drawer title="添加" :wrapperClosable="false" :visible.sync="addFormVisible" direction="rtl"
               :before-close="handleClose" size="35%">
      <div class="drawer-content">
        <el-form :model="addForm" :rules="rules" ref="addForm" label-width="110px" class="demo-ruleForm">
          <el-form-item label="应用名称" prop="appName">
            <el-input v-model="addForm.appName" placeholder="请输入应用名称"></el-input>
          </el-form-item>
          <el-form-item label="商户号" prop="mchNo">
            <el-input v-model="addForm.mchNo" placeholder="请输入商户号"></el-input>
          </el-form-item>
          <el-form-item label="应用状态" prop="status">
            <el-select v-model="addForm.status" placeholder="请选择应用状态">
              <el-option label="停用" :value="0"/>
              <el-option label="启用" :value="1"/>
            </el-select>
          </el-form-item>
          <el-form-item label="应用私钥" prop="appSecret">
            <template #default="scope">
              <el-input v-model="addForm.appSecret" disabled placeholder="请输入应用私钥"></el-input>
              <el-button class="marginTop" plain size="small" icon="el-icon-document-checked"
                         @click="randomKey('add')">随机生成私钥
              </el-button>
            </template>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input v-model="addForm.remark" placeholder="备注"></el-input>
          </el-form-item>
        </el-form>
        <div class="drawer-footer">
          <a-button class="marginRight" @click="addFormCancel">
            取消
          </a-button>
          <a-button class="marginRight" @click="resetForm">
            重置
          </a-button>
          <a-button type="primary" @click="addSubmit">
            确定
          </a-button>
        </div>
      </div>
    </el-drawer>

    <!-- 随机生成私钥弹框 -->
    <el-dialog title="提示" :visible.sync="randomKeyVisible" width="30%" class="dialog-tip">
      <div class="tip">注：该密钥仅展示一次，请妥善保存，点击进行复制</div>
      <div class="random-key-box">
        <el-tooltip class="item" effect="dark" content="点击复制" placement="top">
          <span v-clipboard:copy="randomKeyData" v-clipboard:success="clipboardSuccess"
                v-clipboard:error="clipboardError" class="random-key">{{ randomKeyData }}</span>
        </el-tooltip>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="randomKeySubmit()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改  -->
    <el-drawer title="修改" :wrapperClosable="false" :visible.sync="updateFormVisible" direction="rtl"
               :before-close="handleClose" size="35%">
      <div class="drawer-content">
        <el-form :model="updateForm" :rules="rules" ref="updateForm" label-width="110px" class="demo-ruleForm">

          <el-form-item label="应用名称" prop="appName">
            <el-input v-model="updateForm.appName" placeholder="请输入应用名称"></el-input>
          </el-form-item>
          <el-form-item label="应用状态" prop="status">
            <el-select v-model="updateForm.status" placeholder="请选择应用状态">
              <el-option label="停用" :value="0"/>
              <el-option label="启用" :value="1"/>
            </el-select>
          </el-form-item>
          <el-form-item label="应用私钥" prop="appSecret">
            <template #default="scope">
              <el-input v-model="updateForm.appSecret" disabled placeholder="请输入应用私钥"></el-input>
              <el-button class="marginTop" plain size="small" icon="el-icon-document-checked"
                         @click="randomKey('edit')">随机生成私钥
              </el-button>
            </template>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input v-model="updateForm.remark" placeholder="备注"></el-input>
          </el-form-item>
        </el-form>
        <div class="drawer-footer">
          <a-button class="marginRight" @click="updateFormCancel">
            取消
          </a-button>
          <a-button class="marginRight" @click="resetForm">
            重置
          </a-button>
          <a-button type="primary" @click="editSubmit">
            保存
          </a-button>
        </div>
      </div>
    </el-drawer>

    <!-- 支付配置 -->
    <el-drawer title="添加" :wrapperClosable="false" :with-header="false" :visible.sync="payDispositionVisible"
               direction="rtl" :before-close="handleClose" size="80%" class="drawer">
      <div class="header">
        <a-steps :current="current" type="navigation" class="header-steps" @change="onChange" :disabled="true">
          <a-step title="支付接口参数配置"/>
          <a-step title="支付通道配置"/>
        </a-steps>
      </div>
      <div class="drawer-bgc">
        <div class="content" v-if="current === 0">
          <div class="box">
            <a-card v-for="item in payInterfaceData" :key="item.ifCode" hoverable class="card-box"
                    @click="currentIfCode = item.ifCode">
              <div :class="currentIfCode === item.ifCode ? 'card-black' : ''"></div>
              <img slot="cover" alt="example" class="img"
                   src="https://picnew13.photophoto.cn/20181211/baiseqingxinshangwubeijingsheji-31150691_1.jpg"/>
              <div class="title-info">{{ item.ifName }}</div>
              <div class="show-info">
                <div v-if=item.active>
                  <img slot="cover" alt="example" class="point" src="~@/assets/images/pointSuccess.png"/>
                  已开通
                </div>
                <div v-else>
                  <img slot="cover" alt="example" class="point" src="~@/assets/images/point.png"/>
                  未开通
                </div>
              </div>
              <div class="card-footer">
                <span class="write-argument" @click="updateArgument(item.mchParams, item.ifCode, item.active)">填写参数
                  <a-icon type="right"/></span>
              </div>
            </a-card>
          </div>
        </div>
        <div class="content" v-if="current === 1">
          <div class="drawer-table">
            <el-table :data="payWayData" ref="multipleTable" tooltip-effect="dark">
              <el-table-column type="index" label="序号" min-width="80"></el-table-column>
              <el-table-column prop="ifCode" label="支付接口代码" min-width="120"></el-table-column>
              <el-table-column prop="wayCode" label="支付通道代码" min-width="80">
                <template #default="scope">
                  <el-tag>{{ scope.row.wayCode }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="ifRate" label="费率" min-width="160">
                <template #default="scope">
                  {{ scope.row.ifRate }} %
                </template>
              </el-table-column>
              <el-table-column prop="updateTime" label="更新时间" min-width="160"></el-table-column>
              <el-table-column prop="state" label="状态" min-width="90">
                <template #default="scope">
                  <div @click="changePayWayStatus(scope.row)">
                    <el-switch v-model="scope.row.state" :active-value="1" :inactive-value="0"></el-switch>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div class="footer">
        <a-button icon="close" class="marginRight" @click="closePayConfig">
          关闭
        </a-button>
        <a-button type="primary" icon="arrow-right" @click="changeCurrent">
          {{ current === 0 ? '下一步' : '上一步' }}
        </a-button>
      </div>

      <!-- 填写参数弹框 -->
      <el-drawer title="修改参数" :wrapperClosable="false" :append-to-body="true" :before-close="handleClose"
        :visible.sync="editArgumentVisible" size="35%">
        <div class="drawer-content">
          <el-form :model="argumentForm" ref="argumentForm" label-width="130px" class="demo-addForm drawer-content">
            <div v-for="(item, index) in argumentFormList" :key="index">
              <el-form-item v-if="item.type == 'input'" :label="item.desc" :prop="item.name"
                :required="item.required != 'required'">
                <el-input v-model="argumentForm[item.name]"></el-input>
              </el-form-item>
              <el-form-item v-else-if="item.type == 'radio'" :label="item.desc" :prop="item.name"
                :required="item.required != 'required'">
                <el-radio v-model="argumentForm[item.name]" :label="item.values">{{
                  item.titles
                }}
                </el-radio>
              </el-form-item>
              <el-form-item v-else-if="item.type == 'textarea'" :label="item.desc" :prop="item.name"
                :required="item.required != 'required'">
                <el-input type="textarea" v-model="argumentForm[item.name]"></el-input>
              </el-form-item>
              <el-form-item v-else :label="item.desc" :prop="item.name" :required="item.required != 'required'">
                <el-input value="接口定义JSON异常"></el-input>
              </el-form-item>
            </div>
          </el-form>
          <div class="drawer-footer">
            <a-button class="marginRight" @click="editArgumentClose">
              取消
            </a-button>
            <a-button type="primary" @click="editArgumentSubmit">
              确定
            </a-button>
          </div>
        </div>
      </el-drawer>
    </el-drawer>
  </div>
</template>

<script>
import {
  getMerchantAppInfoData,
  deletesMerchantAppInfo,
  updateMerchantAppInfo,
  updateMerchantAppStatus,
  addMerchantAppInfo,
  randomKey,
} from "@/api/merchantManagement/merchantAppInfo";
import {getPayInterfaceInfoData} from "@/api/payManagement/payInterfaceInfo";
import {
  getPayInterfaceParams,
  addPayInterfaceParams,
  changePayWayStatus,
  getPayWayStatus,
  getIfCodeStatus
} from "@/api/payManagement/payInterfaceParamsInfo";
import Pagination from "@/components/Pagination";

export default {
  name: "merchantAppInfo",
  components: {Pagination},
  data() {
    return {
      //权限
      userRole: false,
      //页头搜索数据
      searchForm: {},
      //保存多选框选中的行数据
      selectedRows: [],
      //渲染列表数据
      tableData: [],
      //总条数
      total: 0,
      //当前页数
      pageNumber: 1,
      //当前每页条数
      pageSize: 5,
      //修改抽屉是否展示
      updateFormVisible: false,
      //修改表单数据
      updateForm: {},
      //添加抽屉是否展示
      addFormVisible: false,
      //添加表单数据
      addForm: {},
      //添加表单-随机生成密钥弹框
      randomKeyVisible: false,
      //添加表单-随机生成密钥
      randomKeyData: '',
      //生成密钥确定按钮方法
      method: '',
      //添加表单数据规则
      rules: {
        appName: [
          {required: true, message: '请输入应用名称', trigger: 'blur'}
        ],
        mchNo: [
          {required: true, message: '请输入商户号', trigger: 'blur'}
        ],
        status: [
          {required: true, message: '请选择状态', trigger: 'blur'}
        ],
        appSecret: [
          {required: true, message: '请输入应用私钥', trigger: 'blur'}
        ],
        remark: [
          {required: true, message: '请输入备注', trigger: 'blur'}
        ],
      },
      //支付配置数据
      payInterfaceData: {},
      //支付配置--当前选中的APPID
      currentAppId: '',
      //支付配置抽屉
      payDispositionVisible: false,
      //支付配置抽屉首次选中
      current: 0,
      //支付配置抽屉-填写参数抽屉是否展示
      editArgumentVisible: false,
      //支付配置抽屉-填写参数抽屉表单
      argumentForm: {},
      //支付配置抽屉-填写参数抽屉数据（生成表单）
      argumentFormList: [],
      // 当前选中的支付接口
      currentIfCode: undefined,
      //选中的支付接口对应的支付通道数据
      payWayData: [],
    };
  },

  mounted() {
    this.getTableData();
    this.getUserRole();
  },

  methods: {
    //分页查询
    async getTableData() {
      const data = {...this.searchForm, pageNum: this.pageNumber, pageSize: this.pageSize};
      const res = await getMerchantAppInfoData(data);
      this.tableData = res.data.rows
      this.total = parseInt(res.data.total)
    },

    //获取权限
    getUserRole() {
      const userRole = localStorage.getItem("userRole");
      console.log("sideNav页面：", userRole);
      if (userRole.includes("admin")) {
        this.userRole = true
      }
    },

    // 页头重置
    resetSearch() {
      this.searchForm = {}
      this.getTableData()
    },

    //通过选中的多行数据存入数组
    handleSelectionChange(selection) {
      this.selectedRows = selection;
      console.log(this.selectedRows)
    },

    //表单重置（添加/修改）
    resetForm() {
      this.addForm = {}
      this.updateForm = {}
    },

    //批量删除按钮
    async deleteList() {
      this.$confirm('此操作将永久删除选中商户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const data = this.selectedRows.map((item) => {
          return item.appId
        })
        console.log(data)
        const res = await deletesMerchantAppInfo(data);
        console.log(res)
        this.$message.success("删除成功");
        this.getTableData();
      }).catch((e) => {
        if (e == 'cancel') {
          this.$message.info("已取消删除");
        }
      });
    },

    //添加按钮
    add() {
      this.addFormVisible = true
    },

    //随机生成私钥按钮
    async randomKey(method) {
      this.method = method
      const res = await randomKey();
      this.randomKeyData = res.data
      this.randomKeyVisible = true
    },

    //随机生成私钥--复制文本成功回调
    clipboardSuccess(e) {
      this.$message(
          {
            message: '复制成功',
            type: 'success',
            customClass: 'z-index'
          }
      );
      console.log(e.text)
    },

    //随机生成私钥--复制文本失败回调
    clipboardError(e) {
      this.$message(
          {
            message: '复制失败',
            type: 'error',
            customClass: 'z-index'
          }
      );
      console.log(e.text)
    },

    //随机生成私钥--弹框确定按钮
    randomKeySubmit() {
      this.randomKeyVisible = false
      if (this.method == 'add') {
        this.$set(this.addForm, 'appSecret', this.randomKeyData)
      } else if (this.method == 'edit') {
        this.$set(this.updateForm, 'appSecret', this.randomKeyData)
      }
    },

    //添加抽屉提交按钮
    async addSubmit() {
      const res = await addMerchantAppInfo(this.addForm)
      this.$message.success("添加成功");
      this.getTableData()
      this.addFormVisible = false
    },

    //添加抽屉取消按钮
    addFormCancel() {
      this.addFormVisible = false
      this.addForm = {}
    },

    //修改状态
    async changeStatus(row) {
      console.log(row.status)
      var text = ""
      if (row.status === 0) {
        text = "禁用成功"
      } else if (row.status == 1) {
        text = "启用成功"
      }
      const res = await updateMerchantAppStatus(row.appId, row.status)
      this.$message.success(text);
    },

    //修改
    editRow(row) {
      this.updateForm = row
      this.updateFormVisible = true
    },

    //修改抽屉保存按钮
    async editSubmit() {
      const res = await updateMerchantAppInfo(this.updateForm)
      this.$message.success("修改成功");
      this.updateFormVisible = false
    },

    //修改抽屉取消按钮
    updateFormCancel() {
      this.updateFormVisible = false
      this.updateForm = {}
    },

    // 删除单条
    deleteRow(id) {
      this.$confirm('此操作将永久删除该商户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const data = [id]
        const res = await deletesMerchantAppInfo(data);
        this.getTableData();
        this.$message.success("删除成功");
      }).catch((e) => {
        if (e == 'cancel') {
          this.$message.info("已取消删除");
        }
      });
    },

    //支付配置按钮
    async payDisposition(row) {
      if (row != '') {
        this.currentAppId = row.appId
      }
      this.payDispositionVisible = true
      const data = {pageNum: '1', pageSize: '100'};
      const res = await getPayInterfaceInfoData(data)
      // 查询每条记录是否已经开通
      const ifCodeList = [];
      res.data.rows.forEach(item => {
        ifCodeList.push(item.ifCode)
      })
      const response = await getIfCodeStatus(this.currentAppId, ifCodeList)
      const activeIfCodeList = response.data
      if (activeIfCodeList != null) {
        res.data.rows.forEach(item => {
          if (activeIfCodeList.includes(item.ifCode)) {
            item.active = true
          } else {
            item.active = false
          }
        })
      }
      console.log(res.data.rows)
      this.payInterfaceData = res.data.rows
    },

    //支付配置---点击步骤条
    onChange(current) {
      console.log('onChange:', current);
      if (this.currentIfCode != undefined) {
        this.changeCurrent()
        this.current = current;
      }
    },

    //支付配置---点击下一步/上一步
    async changeCurrent() {
      if (this.current === 0 && this.currentIfCode != undefined) {
        this.current = 1
        //   获取支付接口的信息
        const res = await getPayWayStatus(this.currentAppId, this.currentIfCode);
        console.log(res)
        this.payWayData = res.data
      } else {
        this.current = 0
      }
    },

    //支付配置---关闭
    closePayConfig() {
      this.payDispositionVisible = false
      this.current = 0
      this.payInterfaceData = {};
    },

    //支付配置---修改参数
    async updateArgument(mchParams, ifCode, active) {
      // 本行测试使用
      this.currentIfCode = ifCode
      this.editArgumentVisible = true
      this.argumentFormList = mchParams
      if (active) {
        const res = await getPayInterfaceParams(this.currentAppId, ifCode)
        this.argumentForm = JSON.parse(res.data.ifParams)
      }
    },

    //支付配置---支付通道配置---修改状态
    async changePayWayStatus(row) {
      var text = ""
      if (row.state === 0) {
        text = "禁用成功"
      } else if (row.state == 1) {
        text = "启用成功"
      }
      const data = {
        "recognizeId": this.currentAppId,
        "ifCode": this.currentIfCode,
        "wayCode": row.wayCode,
        "status": row.state
      }
      const res = await changePayWayStatus(data)
      this.$message.success(text);
    },

    //支付配置---修改参数抽屉关闭回调
    editArgumentClose(done) {
      this.editArgumentVisible = false;
      // this.$confirm('还有未保存的工作哦确定关闭吗？')
      //     .then(_ => {
      //         done();
      //     })
      //     .catch(_ => { });
    },

    //支付配置---修改参数提交
    async editArgumentSubmit() {
      let data = {
        "recognizeId": this.currentAppId,
        "ifCode": this.currentIfCode,
        "ifParams": JSON.stringify(this.argumentForm)
      }
      await addPayInterfaceParams(data)
      this.$message.success("配置成功");
      this.editArgumentVisible = false;
      this.payDisposition('')
    },

    //抽屉关闭回调
    handleClose(done) {
      // this.$confirm('确认关闭？')
      //   .then(_ => {
      //     done();
      //   })
      //   .catch(_ => { });
      done()
      this.addForm = {}
      this.updateForm = {}
      this.argumentForm = {}
    },

    //获取当前页数
    getPage(value) {
      this.pageNumber = value;
      this.getTableData();
    },

    //获取每页多少条
    getSize(value) {
      this.pageSize = value;
      this.getTableData();
    },
  }
}
</script>

<style scoped lang="less">
/* 搜索框样式 */
.input {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;

  .input-font {
    width: 400px;
    display: flex;
    margin-bottom: 10px;

    .fontdiv {
      color: #999;
      font-weight: 600;
      font-size: 15px;
      width: 150px;
      line-height: 36px;
      text-align: center;
    }

    .select-width {
      width: 400px;
    }
  }

  .button-height {
    height: 36px;
    margin-bottom: 10px;
    margin-left: 15px;
  }

}

/* 表格 */
.table {
  margin-top: 15px;

  .marginRight {
    margin-right: 20px;
  }

  :deep .el-table .el-table__header-wrapper .el-table__header .el-table__cell {
    font-size: 14px;
    color: #999;
    font-weight: 600px;
    text-align: center;
    background-color: #f2f2f2;
  }

  :deep .el-table th.el-table__cell.is-leaf {
    background-color: #f2f2f2 !important;
  }

  :deep .el-table .el-table__body-wrapper .el-table__body .el-table__row .el-table__cell .cell {
    text-overflow: clip;
    text-align: center;
  }
}

/* 抽屉内容 */
.drawer-content {
  padding: 0 30px 70px 20px;

  .drawer-footer {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    background: #fff;
    text-align: right;
    z-index: 1;
  }

  .marginRight {
    margin-right: 8px;
  }

  .marginTop {
    margin-top: 10px;
  }
}

// 支付配置
.drawer {
  .header {
    padding: 20px 70px 20px 50px;
    background-color: #fff;

    .header-steps {
      box-shadow: 0px -1px 0 0 #e8e8e8 inset;
    }
  }

  .drawer-bgc {
    background-color: #f0f2f5;
    height: calc(100vh - 156px);

    .content {
      padding: 25px;
      overflow-y: auto;
      padding-bottom: 130px;

      .box {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
        grid-gap: 20px;

        .card-box {
          width: 240px;
          border-radius: 10px;
          border: none;
          position: relative;

          .card-black {
            width: 240px;
            height: 100%;
            border-radius: 10px;
            top: 0;
            left: 0;
            position: absolute;
            background-color: rgba(50, 143, 230, 0.3);
          }

          .card-radio {
            position: absolute;
            top: 15px;
            right: 15px;
            margin-right: 0;
          }

          .img {
            width: 100%;
            height: 170px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          }

          .title-info {
            text-align: center;
            font-size: 18px;
            font-weight: 600;
            color: black;
            margin-bottom: 30px;
            margin-top: 15px;
          }

          .show-info {
            text-align: center;
            font-size: 16px;
            position: relative;
            margin-bottom: 15px;

            .point {
              width: 20px;
              margin-bottom: 3px;
            }
          }

          .card-footer {
            border-top: 0.5px solid #f4f5f7;
            padding: 16px;
            text-align: center;
            color: #3b8cff;
            font-size: 16px;

            .write-argument {
              position: relative;
              z-index: 9;
            }

            span:hover {
              color: #95c0fc;
            }
          }
        }
      }

      .drawer-table {

        :deep .el-table {
          border-radius: 10px;
          padding: 0 20px;
        }

        :deep .el-table .el-table__header-wrapper .el-table__header .el-table__cell {
          font-weight: 600px;
          text-align: center;
        }

        :deep .el-table .el-table__body-wrapper .el-table__body .el-table__row .el-table__cell .cell {
          text-overflow: clip;
          text-align: center;
        }
      }
    }
  }

  .footer {
    position: absolute;
    width: 100%;
    height: 60px;
    background-color: #ffffff;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 0.5px solid #e9e9e9;

    .marginRight {
      margin-right: 15px;
    }
  }

  :deep .ant-card-body {
    padding: 0;
  }
}

// 密钥展示
.dialog-tip {

  /* 密钥弹框提示语句*/

  .tip {
    text-align: center;
    color: #ff3434;
    margin-bottom: 10px;
  }

  .random-key-box {
    border: 1px solid #999;
    border-radius: 10px;
    padding: 10px;
    max-height: 100px;
    font-size: 18px;
    color: #333;
    overflow-y: auto;

    .random-key {
      cursor: pointer;
      padding-bottom: 2px;
    }

    .random-key:hover {
      text-decoration: underline;
      text-decoration-style: dashed;
    }
  }
}
</style>